import { createStore as reduxCreateStore } from "redux";

const reducer = (state, action) => {
    if (action.type === `INCREMENT`) {
        return Object.assign({}, state, {
            count: state.count + 1,
        })
    }
    if (action.type === `OPEN_NAV`) {
        return Object.assign({}, state, {
            navOpen: 1,
        })
    }
    if (action.type === `CLOSE_NAV`) {
        return Object.assign({}, state, {
            navOpen: 0,
        })
    }
    if (action.type === `TOGGLE_NAV`) {
        return Object.assign({}, state, {
            navOpen: state.navOpen ? 0 : 1,
        })
    }
    return state
};

const initialState = {
    count: 0,
    navOpen: false
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;